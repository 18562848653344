<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
                <a-descriptions-item label="司机">
					{{ detail.chauffeur||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="司机电话">
					{{ detail.chauffeur_mobile||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="车牌">
					{{ detail.truck_license||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="仓管">
					{{ detail.username||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="仓库">
					{{ detail.storage_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="归还时间">
					{{ detail.create_time }}
				</a-descriptions-item>
				<a-descriptions-item label="状态">
					{{ detail.status_name }}
				</a-descriptions-item>
				<a-descriptions-item label="入库时间">
					{{ detail.finish_time?detail.finish_time:'' }}
				</a-descriptions-item>
			</a-descriptions>

            <div v-if="seller.length > 1">
                <div class="seller_box">
                    <a-button style="margin-right:10px" v-for="(value,index) in seller" :key="index" @click="changeSeller(value)">客户{{ index + 1 }}</a-button>
                </div>
            </div>

            <a-divider orientation="left">经销商信息</a-divider>
            <a-descriptions :column="2">
                <a-descriptions-item label="经销商名称">
					{{ detail.seller_data.seller_name||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="经销商编号">
					{{ detail.seller_data.seller_id||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="联系电话">
					{{ detail.seller_data.phone||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="负责人">
					{{ detail.seller_data.principal||'--' }}
				</a-descriptions-item>
                <a-descriptions-item label="地址">
					{{ detail.seller_data.address||'--' }}
				</a-descriptions-item>
			</a-descriptions>

			<a-divider orientation="left">托盘信息</a-divider>	
            <div class="seller_box" v-if="detail.deposit_ids">  
                <a-button type="primary" @click="go_deposit(value)">查看寄存</a-button>
            </div>
            <div class="top_box">
                <div>托盘总数：{{ detail.num.total_num }}</div>
                <div>完好数量：{{ detail.num.good_num }}</div>
                <div>损坏数量：{{ detail.num.bad_num }}</div>
            </div>
            <div class="table_box">
                <div class="tr_box">
                    <div v-for="(value,index) in detail.goods_data.title" :key="index">{{ value.label }}</div>
                </div>
                <div>
                    <div v-for="(value,index) in detail.goods_data.goods_data" :key="index" class="td_box">
                        <div v-for="(va,ind) in detail.goods_data.title">{{ value[va.value].data }}</div>
                    </div>
                </div>
            </div>

            <a-divider orientation="left" v-if="detail.num.bad_num > 0">损坏托盘生产日期</a-divider>
            <div class="product_box" v-if="detail.num.bad_num > 0">
                <div v-for="(value,index) in detail.product_data" :key="index" class="product_info">
                    <div class="product_top">
                        {{ value.goods_name }}损坏数量：{{ value.total_num }}
                    </div>
                    <div class="product_box">
                        <div class="product_tr">
                            <div>数量（块）</div>
                            <div>托盘生产日期</div>
                        </div>
                        <div v-for="(va,ind) in value.data" :key="ind" class="product_td">
                            <div>{{ va.num }}</div>
                            <div>{{ va.product_date }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <a-divider orientation="left" v-if="detail.num.bad_num > 0">损坏图片</a-divider>
            <div v-if="detail.num.bad_num > 0">
                <div  v-for="(value,index) in detail.image_data" :key="index">
                    <div>{{ value.goods_name }}</div>
                    <div v-for="(va,ind) in value.bad" :key="ind" class="image_info">
                        <div class="back_img" :style="{background:'url('+va.image+')', backgroundSize:'100%', backgroundRepeat: 'no-repeat'}"></div>
                    </div>
                </div>
            </div>
            
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		get_tray_order_detail,
        get_tray_order_seller
	} from '@/api/push.js'

	export default {
		components: {
            
		},
		data() {
			return {
				detail: {
                    num:{
                        total_num:0,
                        good_num:0,
                        bad_num:0
                    },
                    seller_data:{
                        seller_name:'',
                        seller_id:'',
                        phone:'',
                        principal:'',
                        address:'',
                    },
                    goods_data:{
                        title:[],
                        goods_data:[]
                    }
                },
                seller:[]
			}
		},
		created() {
			this.get_seller()
		},
		methods: {
            get_seller() {
                get_tray_order_seller({
                    data: {
                        order_id:this.$route.query.id
                    }
                }).then(res => {
                    this.seller = res.data.list
                    this.get_info(this.seller[0])
                })
            },
            changeSeller(data) {
                this.get_info(data)
            },
			get_info(e) {
				get_tray_order_detail({
					data: {
						order_id: e.order_id,
                        tray_id:e.tray_id,
                        sales_seller_id:e.sales_seller_id
					}
				}).then(res => {
					this.detail = res.data.list
				})
			},
            go_deposit() {
                this.$router.push({
                    path: '/deposit/index',
                    query: {
                        deposit_ids: this.detail.deposit_ids
                    }
                })
            }
		}
	}
</script>

<style lang="less" scoped>

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

    .seller_box{
        margin-bottom: 20px;
    }

    .top_box {
        display: flex;
        width: 750px;
        height: 33px;
        align-items: center;
        background-color: #FAFAFA;
        margin-bottom: 10px;
        div {
            width: 30%;
            color: #1890FF;
        }
    }

    .table_box {
        width: 750px;
        .tr_box {
            display: flex;
            margin-bottom: 15px;
        }
        .tr_box div {
            width: 25%;
        }
        .td_box{
            display: flex;
        }
        .td_box div {
            width: 25%;
            height: 30px;
        }
    }

    .product_box {
        width: 500px;
        .product_top {
            margin-bottom: 10px;
            background-color: #FAFAFA;
            line-height: 30px;
            color: #1890FF;
        }

        .product_info{
            margin-bottom: 20px;
        }

        .product_tr {
            display: flex;
            margin-bottom: 15px;
            div{
                width: 50%;
            }
        }

        .product_td {
            display: flex;
            div{
                width: 50%;
                height: 30px;
            }
        }
    }

    // .image_info {
    //     display: flex;
    // }

    .image_info {
        width: 100px;
        margin-right: 8px;
        margin-top: 10px;
        height: 100px;
        display: inline-block;
        .back_img {
            width: 100%;
            height: 100%;
        }
        img{
            height: 100%;
        }
    }
    
</style>
